import React, { useState, useEffect } from "react";
import useIsMobile from "../hooks/useIsMobile";
import CommonHomeCard from "./commonHomeCard";
import TopicSlider from "./topics/topicSlider";
import { configuration } from "../helpers/config";
import { validateUrl } from "../utils/GenUtils";
import { Constants } from "../helpers/constants";
import { CarPageAllSections } from "../pages/pages/templates/all_components";
import useWindowSize from "../hooks/useWindowSize";
//component to render different placeholder layouts in homepage.
export default function HomeCmsLinks({ cmspages }) {
  // const [data,setdata]= useState(CarPageAllSections)
  const mobile = useIsMobile();
  const windoWidth = useWindowSize();

  // defining styles for different column layouts
  const columnOne = {
    gridTemplateColumns: "1fr",
  };
  const columnTwo = {
    gridTemplateColumns: "1fr 1fr",
  };
  const columnThree = {
    gridTemplateColumns: "1fr 1fr 1fr",
  };
  const columnFour = {
    gridTemplateColumns: "1fr 1fr 1fr 1fr",
  };
  const [windowPath, setWindowPath] = useState(false);
  useEffect(() => {
    if (window) {
      const pathname = window.location.pathname;
      if (pathname === "/" || pathname.includes("/preview/")) {
        setWindowPath(true);
      } else {
        setWindowPath(false);
      }
    }
  }, []);

  function formatDate(dateString) {
    const datePart = dateString;

    // Split the date string into an array [YYYY, MM, DD]
    const [year, month, day] = datePart.split("-");

    // Return the formatted date as DD/MM/YYYY
    return `${day}-${month}-${year}`;
  }

  return (
    <div>
      {cmspages?.map((item, i) => (
        <React.Fragment key={i}>
          {item.column > 2 && mobile ? (
            <div className="topics__container" id="topicSection">
              {item.column > 3 && (
                <h5 className="topics__container--heading">{item.title}</h5>
              )}
              <TopicSlider
               showHeading={item.column <= 3} 
               data={item.pages} 
               image={item?.isImage}
               pageTitle={item?.title}
               showThreeColumnData={item?.column==3 && item?.pages &&  Array.isArray(item?.pages) && item?.pages?.length==3}
                type={"Slider"}
                cta={item}
              />
            </div>
          ) : item.column >= 4 && !mobile ? (
            <div className="topics__container pr-[40px]">
              <h5 className="topics__container--heading">{item.title}</h5>
              <TopicSlider
                showHeading={false}
                data={item.pages}
                column={item.column}
                type={item.type}
                image={item?.isImage}
                pageTitle={item?.title}
                cta={item}
              />
            </div>
          ) : item.type == "Slider" ? (
            <div className="topics__container" id="topicSection">
              <h5 className="topics__container--heading">{item.title}</h5>
              <TopicSlider
                showHeading={false}
                data={item.pages}
                column={item.column}
                image={item?.isImage}
                type={item.type}
                cta={item}
              />
            </div>
          ) : (
            <>
              {item.title &&
                !item.title.toLowerCase().includes("placeholder") && (
                  <div className="container" id="topicSection">
                    {item.title && (
                      <h5 className="mb-[40px] topics__container--heading">
                        {item?.title}
                      </h5>
                    )}
                  </div>
                )}
              {/* <div className="topics__container"> */}
              {/* </div> */}
              <div
                key={i + Math.random()}
                // key={i + crypto.getRandomValues(new Uint8Array(1))[0]}
                className={
                  item.column === 1
                    ? "homeCms__container" 
                    : "homeCms__container container-with-padding container mx-auto gap-x-[30px] flex flex-col sm:flex-row"
                }
                
                style={
                  mobile
                    ? columnOne
                    : item.column == 1
                    ? columnOne
                    : item.column == 2
                    ? columnTwo
                    : item.column == 3
                    ? columnThree
                    : item.column == 4
                    ? columnFour
                    : columnOne
                }
              >
               
                {item.pages.map((page, i) => {
                  if (page?.page_settings?.page_status) {
                    let isValidUrl = validateUrl(page?.primary_details?.slug);
                    return (
                      <CommonHomeCard
                        key={i + Math.random()}
                        Id={
                          "HomeLink" +
                          page?.primary_details?.banner_title?.replace(
                            /\s+/g,
                            ""
                          )
                        }
                        // different image keys based on column numbers
                        skeletonwidth={400}
                        skeletonwidthDesktop = {item?.column === 1 ? windoWidth?.width : item?.column === 2 ? 600 : 370}
                        imgSrc = {
                          page?.images
                            ? !windowPath  &&  item?.isImage
                              ? (() => {
                                  if (item?.column === 1) {
                                    return page?.images?.desk_banner
                                      ? configuration.IMG_URL + page?.images?.desk_banner
                                      : "";
                                  } else if (item?.column === 3) {
                                    return page?.images?.desk_medium
                                      ? configuration.IMG_URL + page?.images?.desk_medium
                                      : "";
                                  } else if (item?.column > 3) {
                                    return page?.images?.desk_thumb
                                      ? configuration.IMG_URL + page?.images?.desk_thumb
                                      : "";
                                  } else {
                                    return page?.images?.desk_banner
                                      ? configuration.IMG_URL + page?.images?.desk_banner
                                      : "";
                                  }
                                })()
                              : page?.images?.desk_placeholder
                              ? configuration.IMG_URL + page?.images?.desk_placeholder
                              : ""
                            : ""
                        }
                        alt_text={
                          !windowPath
                            ? item?.column == 1
                              ? page?.images?.desk_banner_alt
                              : item?.column === 3
                              ? page?.images?.desk_medium_alt
                              : item?.column > 3
                              ? page?.images?.desk_thumb_alt
                              : page?.images?.desk_banner_alt
                            : page?.images?.desk_placeholder_alt
                        }
                        imgMobileSrc={
                          page?.images
                        ? !windowPath && item?.isImage
                          ? item?.column === 1 && page?.images?.mob_banner
                            ? configuration.IMG_URL + page?.images?.mob_banner
                            : page?.images?.mob_thumb
                            ? configuration.IMG_URL + page?.images?.mob_thumb
                            : ""
                          : page?.images?.mob_placeholder
                          ? configuration.IMG_URL + page?.images?.mob_placeholder
                          : ""
                        : ""
                        }
                        alt_text_mob={
                          !windowPath
                            ? item?.column == 1
                              ? page?.images?.mob_banner_alt
                              : page?.images?.mob_thumb_alt
                            : page?.images?.mob_placeholder_alt
                        }
                        cardClassName={`${
                          item?.column === 3
                            ? "homeCms__cardLinks threeColumnRadius"
                            : "homeCms__cardLinks"
                        }`}
                        modifyHead={
                          item?.column === 3 &&
                          "min-h-[75px] lg:min-h-[75px] md:min-h-[75px]"
                        }
                        modifyDesc={
                          item?.column === 3 &&
                          "h-[64px] lg:h-[64px] md:h-[64px]"
                        }
                        contentClassName={` ${
                          item?.column === 1 && "md:max-w-[75%]"
                        } ${item?.column === 3 && "md:max-w-[80%]"} `}
                        headClassName={`${
                          item?.column === 1 && "text-[#000]"
                        } ${
                          item?.column === 3 &&
                          "min-h-[75px] lg:min-h-[75px] md:min-h-[75px] "
                        }`}
                        descriptClass={`${
                          item?.column === 3 &&
                          "h-[64px] lg:h-[64px] md:h-[64px]"
                        }`}
                        imgHeight={
                          item?.column == 1
                            ? 738
                            : item?.column == 2
                            ? 400
                            : item?.column == 3
                            ? 302
                            : 200
                        }
                        imgWidth={
                          item?.column == 1
                            ? 1920
                            : item?.column == 2
                            ? 753
                            : item?.column == 3
                            ? 545
                            : 300
                        }
                        videoLink={
                          item?.column == 1 && !item?.isImage && page?.video
                        }
                        imgMobileHeight={564}
                        imgMobileWidth={768}
                        cardTitle={page?.primary_details?.title}
                        cardDescription={page?.primary_details?.desc}
                        cardBtnText={Constants.homePage.SEE_DETAILS}
                        cardLink={
                          !isValidUrl
                            ? CarPageAllSections.includes(
                                (page?.primary_details?.slug).split("#")[1]
                              )
                              ? `/${page?.primary_details?.slug}`
                              : `/pages/${page?.primary_details?.slug}`
                            : page?.primary_details?.slug
                        }
                        btnType={"button_dark_arrow"}
                        hideCta={!item.isCta}
                        date={
                          page?.primary_details?.date &&
                          formatDate(page?.primary_details?.date)
                        }
                        cta={item.isCta ? true : false}
                        date_enabled={page?.primary_details?.date_enabled}
                      />
                    );
                  }
                })}
              </div>
            </>
          )}
        </React.Fragment>
      ))}
    </div>
  );
}
